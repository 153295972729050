import styled from "@emotion/styled";

const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
`

const TableHeader = styled.th`
  margin: 10px;
  border-bottom: 2px solid grey;
  width: 100vw;
  min-width: 10vw;
  background-color: rgb(204, 214, 221);
  text-transform: capitalize;
  padding: 10px;

  &:first-of-type {
    border-radius: 20px 0 0 0;
  }

  &:last-child {
    border-radius: 0 20px 0 0;
  }
`;

const TableData = styled.td`
  margin: 10px;
  text-align: center;
  border-top: 2px solid grey;
`;

export const ResultsTable = (props) => {
  const { correctSpelling, attemptedSpelling, algorithms, algoResults } = props;
  let numRows = 1;
  //let algoResults = {'levenshtein': 2, 'damerau_levenshtein': 2, 'cosine': 0.25, 'editex': 2, 'prefix': 6, 'postfix': 4};

  const Table = styled.table`
    height: calc(${numRows} * 5vh + 3vh);
    margin: 0;
    table-layout: fixed;
    background-color: whitesmoke;
    border-radius: 20px;
  `;

  return (
    <TableContainer>
      <Table>
        <tbody>
          <tr>
            <TableHeader>Correct Spelling</TableHeader>
            <TableHeader>Attempted Spelling</TableHeader>
            {algorithms.map(
              (algo, key) => (
                <TableHeader key={key}>{algo.label}</TableHeader>
              ) //Key is only there to remove the warning
            )}
          </tr>
          <tr>
            <TableData>
              {correctSpelling === "" ? "-" : correctSpelling}
            </TableData>
            <TableData>
              {attemptedSpelling === "" ? "-" : attemptedSpelling}
            </TableData>
            {/* <TableData>
              {correctSpelling === attemptedSpelling && correctSpelling !== ""
                ? "✔"
                : "❌"}
            </TableData> */}
            {algorithms.map(
              (algo, key) => (
                <TableData key={key}>{algoResults[algo.value]}</TableData>
              ) //Key is only there to remove the warning
            )}
          </tr>
        </tbody>
      </Table>
    </TableContainer>
  );
};
