import { useState, useEffect } from "react";
import axios from "axios";
import { Flex } from "@chakra-ui/react";

import { Navbar, DemoSpellingInput, ResultsTable } from "components";
import styled from "@emotion/styled";

axios.defaults.headers.common["ngrok-skip-browser-warning"] = "true";

const Container = styled.div`
  margin: 20px 10vw;
`

const DemoContainer = styled.div`
    display: flex;
`;

const SpellingInputContainer = styled.div`
    width: 40%;
    margin: 0 10px 0 0;
`;

function Demo() {
    const [correctSpelling, setCorrectSpelling] = useState("");
    const [attemptedSpelling, setAttemptedSpelling] = useState("");
    const [algorithms, setAlgorithms] = useState([]);
    const [algoResults, setAlgoResults] = useState({});

    useEffect(() => {
        async function fetchResults() {
            const params = {
                string1: attemptedSpelling,
                string2: correctSpelling,
                algorithms: algorithms.map((i) => i.value).join('|'),
            };
            //console.log(algorithms.map((i) => i.value));
            await axios
                .get("https://af07hn3hej.execute-api.us-west-2.amazonaws.com/default/algorithm", { params })
                .then((response) => {
                    console.log(response)
                    setAlgoResults(response.data);
                });
        }
        fetchResults();
    }, [attemptedSpelling, correctSpelling, algorithms]);

    return (
        <Flex direction="column" minH="100vh">
            <Navbar />
            <Container>
                <h1>Algorithm Demo</h1>
                <DemoContainer>
                    <SpellingInputContainer>
                        <DemoSpellingInput
                            setCorrectSpelling={setCorrectSpelling}
                            setAttemptedSpelling={setAttemptedSpelling}
                            setAlgorithms={setAlgorithms}
                        />
                    </SpellingInputContainer>
                    <ResultsTable
                        correctSpelling={correctSpelling}
                        attemptedSpelling={attemptedSpelling}
                        algorithms={algorithms}
                        algoResults={algoResults}
                    />
                </DemoContainer>
            </Container>
        </Flex>
    );
}

export default Demo;
