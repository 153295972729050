import React from "react";
import { Flex } from "@chakra-ui/react";
import { Navbar, AlgoTable, TeamCard } from "components";
import styled from "@emotion/styled";
import { algorithms } from "../algorithms";

let  { members } = require("../teamMemberInfo.js");

const Container = styled.div`
  margin: 20px 10vw;
`

const AboutSpelling = styled.div`
  margin-bottom: 30px;

  a {
    color: var(--chakra-colors-teal-500);
  }
   a:hover {
    color: #2c7a7b;
  }
   }
`

const AboutAlgo = styled.div`
  margin-bottom: 30px;
`

const AboutTeam = styled.div`
 margin-bottom: 30px;
`

const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

const About: React.FC = () => {
  const [selectedAlgo, setSelectedAlgo] = React.useState(algorithms[0]);
  // const [selectedAlgo, setSelectedAlgo] = React.useState(algoTableDefinitions[0]);

  return (
    <Flex direction="column" minH="100vh">
      <Navbar />
      <Container>
        <h1>About</h1>
        <AboutSpelling>
          <h2>What is Spelling Dystance?</h2>
          {/* <p>Spelling Dystance is a website that offers a range of scoring methods for spelling that go beyond the binary classification of correct or incorrect. These scoring methods allow for the analysis of spelling by providing partial credit for various aspects of the spelling. Traditionally, accurately scoring spelling with degrees of correctness would either involve laborious human effort (coding spelling errors) or coding in programming languages to employ "text distance" algorithms. Our approach offers a more intuitive solution by utilizing various text distance algorithms in a way that can easily be accessed by anyone. You can explore different scores for individual words and even evaluate batches of student spelling. If you have any questions, encounter any issues, or have suggestions, please don't hesitate to contact us at <b>moonys@uci.edu</b>.</p> */}
          <p>Spelling Dystance is a website that offers a range of scoring methods for spelling that go beyond the binary classification of correct or incorrect. These scoring methods allow for the analysis of spelling by providing partial credit for various aspects of the spelling. Traditionally, accurately scoring spelling with degrees of correctness would either involve laborious human effort (coding spelling errors) or coding in programming languages to employ "text distance" algorithms. Our approach offers a more intuitive solution by utilizing various text distance algorithms in a way that can easily be accessed by anyone. You can explore different scores for individual words and even evaluate batches of student spelling. The website uses the “textdistance” library in Python. The specific algorithms used in this website are Levenshtein, Damerau-Levenshtein, Cosine distance, Editex, Prefix similarity, and Postfix similarity. For more information on the python library and each algorithm, please visit the <a href="https://pypi.org/project/textdistance/">textDistance website</a>.</p>
          <p>If you have any questions, encounter any issues, or have suggestions, please don't hesitate to contact us at <b>moonys@uci.edu</b>.</p>
        </AboutSpelling>
        <AboutAlgo>
          <h2>Employed Algorithms</h2>
          <AlgoTable definitions={ algorithms } selectedAlgo = { selectedAlgo } setSelectedAlgo = {setSelectedAlgo}/>
        </AboutAlgo>
        <AboutTeam>
          <h2>Our Team</h2>
          <Cards>
            {members.map((member, key) => {
              return <TeamCard key={key} name={member.name} role={member.role} photo={member.photo} photoSize={member.photoSize} mail={member.mail} linkedIn={member.linkedIn} description={member.description} />
            })}
          </Cards>
        </AboutTeam>
      </Container>
    </Flex>
  );
};

export default About;