import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Logo } from "components/logo";


const Bar = styled.div`
	display: flex;
	background-color: #0C45A7;
	padding: 20px;
	justify-content: space-between;
`;

const NavButton = styled.button`
	margin: 0 10px;
  	padding: 10px;
  	font-size: 24px;
  	border-radius: 15px;
  	color: white;
  	font-weight: bold;
	transition: 0.25s;
  	&:hover, &.selected-page {
    	background-color: #FFE867;
    	color: black;
  	}
`;

const DesktopNav = () => {
	return(
		<Bar>
			<Logo />

			<div>
				<Link to="/">
					<NavButton
						className={window.location.href.endsWith("/") ? 'selected-page' : ''}
					>Home</NavButton>
				</Link>

				<Link to="/demo">
					<NavButton
						className={window.location.href.endsWith("/demo") ? 'selected-page' : ''}
					>Demo Page</NavButton>
				</Link>


				<Link to="/analyze">
					<NavButton
						className={window.location.href.endsWith("/analyze") ? 'selected-page' : ''}
					>Analyze</NavButton>
				</Link>

				<Link to="/about">
					<NavButton
						className={window.location.href.endsWith("/about") ? 'selected-page' : ''}
					>About</NavButton>
				</Link>
			</div>

		</Bar>
	);
}

export const Navbar:React.FC = () => {
	return(
		<DesktopNav />
	)
};
