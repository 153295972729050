import React from "react";
import styled from "@emotion/styled";

const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 200px;
  height: 30vh;
  margin-bottom: 20px;
`

const Cards = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  overflow-y: scroll;
`

const Information = styled.div`
  width: 70%;
  margin: 10px 10px 10px 20px;
  background-color: rgb(235, 241, 247);
  padding: 20px;
  border-radius: 20px;
  overflow-y: auto;
  
`

const AlgorithmCard = styled.div`
  border-radius: 20px;
  background-color: var(--chakra-colors-teal-500);
  padding: 15px;
  margin: 10px;
  text-align: center;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #2c7a7b;
  }

  &.pressed {
    background-color: #2c7a7b;
  }
`

const AlgoName = styled.p`
  text-align: center;
  font-size: 24pt;
  font-weight: bold;
  margin-bottom: 10px;
`

export const AlgoTable = (props) => {
  const {definitions, selectedAlgo, setSelectedAlgo} = props;

  console.log(selectedAlgo);

  return (
    <TableContainer>
      <Cards>
        {definitions.map((def) => {
            return (<AlgorithmCard
              onClick={() => setSelectedAlgo(def)}
              className={def.label === selectedAlgo.label ? 'pressed' : ''}
            >{def.label}</AlgorithmCard>);
        })}
      </Cards>
      <Information>
        <AlgoName>{selectedAlgo.name}</AlgoName>
        <p>{selectedAlgo.definition}</p>
      </Information>
    </TableContainer>
  );
};