import styled from "@emotion/styled";
import React, { useState } from "react";
import { Input, Button } from "@chakra-ui/react";
import { DropdownMenu } from "components/dropdownMenu";

const SIContainer = styled.div`
    border-radius: 20px;
    background-color: rgb(204, 214, 221);
    padding: 20px;

    label {
        font-weight: bold;
    }

    Input {
        margin: 5px 0 10px 0;
    }
`;

const DemoButton = styled(Button)`
    margin-top: 20px;
`;

export const DemoSpellingInput = (props) => {
    const { setCorrectSpelling, setAttemptedSpelling, setAlgorithms } = props;
    const [cSpelling, setCSpelling] = useState("");
    const [aSpelling, setASpelling] = useState("");
    const [cAlgorithms, setcAlgorithms] = useState([]);

    return (
        <SIContainer>
            <label htmlFor="correctly-spelled">Correctly Spelled: </label>
            <Input
                name="correctly-spelled"
                placeholder="Correctly Spelled Word"
                bg="white"
                size="md"
                onChange={(e) => setCSpelling(e.target.value.toLowerCase())}
            />
            <label htmlFor="attempted-spelling">Attempted Spelling: </label>
            <Input
                name="attempted-spelling"
                placeholder="Spell word here"
                bg="white"
                size="md"
                onChange={(e) => setASpelling(e.target.value.toLowerCase())}
            />
            <DropdownMenu setAlgorithms={setcAlgorithms} />
            <DemoButton
                colorScheme="teal"
                onClick={() => {
                    setCorrectSpelling(cSpelling);
                    setAttemptedSpelling(aSpelling);
                    setAlgorithms(cAlgorithms);
                }}
            >
                Submit Spelling
            </DemoButton>
        </SIContainer>
    );
};
