import { ChakraProvider } from "@chakra-ui/react";
import theme from "definitions/chakra/theme";
import "styles/global.scss";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "pages/home";
import Demo from "pages/demo";
import About from "pages/about";
import Analyze from "pages/analyze";
import Teacher from "pages/teacher";

function App(): JSX.Element {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/about" element={<About />} />
            <Route path="/analyze" element={<Analyze />} />
            <Route path="/teacher" element={<Teacher />} />
          </Routes>
        </div>
      </ChakraProvider>
    </Router>


  );
}

export default App;
