import {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Flex, FormControl, FormLabel, Input, Stack, Tooltip, useToast} from "@chakra-ui/react";
import {DropdownMenu, Navbar} from "components";
import styled from "@emotion/styled";
import axios from "axios";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'any';

const Container = styled.div`
  margin: 20px 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  justify-content: center;
  display: flex;
  font-size: 40pt;
  flex-grow: 1;
  margin-top: 30px;
`;

const AnalyzerTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  flex-basis: 100%;
  padding: 30px;
`;

const SectionHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-basis: 100%;

  p {
    width: 45%;
    text-align: center;
    font-size: 16pt;
    font-weight: 400;
    margin-bottom: 10px;
  }
`;

const AnalyzerInput = styled.div`
  border-radius: 20px;
  background-color: rgb(204, 214, 221);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 45%;

  label {
    font-weight: bold;
    padding: 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  background-color: whitesmoke;
  border-radius: 20px;
`;

const TableHeader = styled.th`
  margin: 10px;
  border-bottom: 2px solid grey;
  width: 90%;
  background-color: rgb(204, 214, 221);
  text-transform: capitalize;

  &:first-of-type {
    border-radius: 20px 0 0 0;
  }

  &:last-child {
    border-radius: 0 20px 0 0;
  }
`;

const TableData = styled.td`
  margin: 10px;
  text-align: center;
  border-top: 2px solid grey;
`;

const TableContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Analyze = () => {
    const toast = useToast();
    const [file, setFile] = useState(FileList[""]);
    const [algorithms, setAlgorithms] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        const data = await readFile(file);
        setLoading(true);

        try {
            let response = await axios.post(
                'https://af07hn3hej.execute-api.us-west-2.amazonaws.com/default/batch-process',
                {data, algorithms: algorithms.map((i) => i.value).join('|')}
            )
            console.log(response);

            if (response['status'] === 200) {
                downloadFile({
                    data: response['data'],
                    filename: `results-${file.name}`,
                })

                toast({
                    title: 'Upload successful.',
                    description: 'Your results are ready for download.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: 'Upload failed.',
                    description: 'Please try again.',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Flex direction="column" minH="100vh">
            <Navbar />
            <Container>
                <Header>
                    <Heading>Analyze Your Data</Heading>
                </Header>

                <AnalyzerTop>
                    <SectionHeaders>
                        <p>File Uploader</p>
                        <p>Example CSV</p>
                    </SectionHeaders>
                    <AnalyzerInput>
                        <form onSubmit={handleSubmit}>
                            <FormControl isRequired>
                                <FormLabel>Import Scores</FormLabel>
                                <Tooltip label='Maximum 20MB file size'>
                                    <Input type="file" accept=".csv" style={{padding: '5px'}} isRequired
                                           onChange={event => {
                                               if (event.target.files[0].type !== 'text/csv') {
                                                   toast({
                                                       title: 'Upload failed.',
                                                       description: 'File type must be CSV.',
                                                       status: 'error',
                                                       duration: 9000,
                                                       isClosable: true,
                                                   })
                                               } else if (event.target.files[0].size / 1024 > 20480) {
                                                   toast({
                                                       title: 'Upload failed.',
                                                       description: 'Maximum file size is 20MB.',
                                                       status: 'error',
                                                       duration: 9000,
                                                       isClosable: true,
                                                   })
                                               } else {
                                                   setFile(event.target.files[0])
                                               }
                                           }} />
                                </Tooltip>
                                <Stack direction='column' spacing={4} align='left'>
                                    <DropdownMenu setAlgorithms={setAlgorithms} />
                                    <Button colorScheme="teal" size="md" type="submit" isLoading={loading}>
                                        Download Results
                                    </Button>
                                </Stack>

                            </FormControl>
                        </form>
                    </AnalyzerInput>
                    <TableContainer>
                        <Table>
                            <tbody>
                                <tr>
                                    <TableHeader>id</TableHeader>
                                    <TableHeader>target</TableHeader>
                                    <TableHeader>spelling</TableHeader>
                                </tr>
                                <tr>
                                    <TableData>1</TableData>
                                    <TableData>bug</TableData>
                                    <TableData>bug</TableData>
                                </tr>
                                <tr>
                                    <TableData>2</TableData>
                                    <TableData>bug</TableData>
                                    <TableData>big</TableData>
                                </tr>
                                <tr>
                                    <TableData>3</TableData>
                                    <TableData>bug</TableData>
                                    <TableData>bi</TableData>
                                </tr>
                                <tr>
                                    <TableData>1</TableData>
                                    <TableData>family</TableData>
                                    <TableData>family</TableData>
                                </tr>
                                <tr>
                                    <TableData>2</TableData>
                                    <TableData>family</TableData>
                                    <TableData>famili</TableData>
                                </tr>
                                <tr>
                                    <TableData>3</TableData>
                                    <TableData>family</TableData>
                                    <TableData>famly</TableData>
                                </tr>
                                <tr>
                                    <TableData>1</TableData>
                                    <TableData>appropriate</TableData>
                                    <TableData>appropriate</TableData>
                                </tr>
                                <tr>
                                    <TableData>2</TableData>
                                    <TableData>appropriate</TableData>
                                    <TableData>appropreate</TableData>
                                </tr>
                                <tr>
                                    <TableData>3</TableData>
                                    <TableData>appropriate</TableData>
                                    <TableData>apropriate</TableData>
                                </tr>
                            </tbody>
                        </Table>
                        <Link
                            to="https://docs.google.com/spreadsheets/d/1yluFe8mqIuC-IEox3yWhmAmMyN2VfS5d/edit?usp=drive_link&ouid=115128401210095626067&rtpof=true&sd=true"
                            target="_blank">
                            <Button colorScheme="teal" size="md" style={{marginTop: "20px"}}>
                                Sample CSV
                            </Button>
                        </Link>
                    </TableContainer>
                </AnalyzerTop>
            </Container>
        </Flex>
    );
};

export default Analyze;


/**
 * Read input file into memory buffer and return romise with text contents
 *
 * @param {File} file - HTML Input File object (not array/FileList)
 * @return {Promise<string>}
 */
function readFile(file) {
    return new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            const text = e.target.result
            res(text)
        };
        reader.onerror = (e) => {
            rej(e)
        }
        reader.readAsText(file)
    })
}


/**
 * Download a file from the browser to user's computer
 *
 * @param {String} data text data for file contents
 * @param {String} filename name of file to download
 * @param {String} [fileType] defaults to 'text/csv'
 */
function downloadFile ({ data, filename, fileType = 'text/csv' }) {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([ data ], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a        = document.createElement('a');
    a.download     = filename;
    a.href         = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view:       window,
        bubbles:    true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
}
