import React from "react";
import styled from "@emotion/styled";

const Card = styled.div`
  padding: 20px;
  margin: 10px 0;
  width: 25vw;
  background-color: rgb(235, 241, 247);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`

const BeeContainer = styled.div`
  padding-bottom: 0px;
`

const BeeLogo = styled.img`
  height: 50px;
  align-item: left;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

`

const UserPic = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
`

const TeamName = styled.p`
  font-size: 18pt;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 10px;
`

const MemberRole = styled.p`
  font-size: 15pt;
  text-align: center;
  color: #26235C;
`

const MemberDescription = styled.p`
flex-grow: 1;
`

const Email = styled.a`
  border: solid #0C45A7 1px;
  background-color: #0C45A7;
  border-radius: 10px;
  padding: 10px;
  color: white;
  &:hover {
    border: solid #0d357a 1px;
    background-color: #0d357a;
  }
`

const LinkedIn = styled.a`
  img {
    filter: invert(26%) sepia(66%) saturate(6420%) hue-rotate(200deg) brightness(91%) contrast(92%);
    height: 50px;
    margin-top: 10px;
  }
  &:hover {
    filter: invert(26%) sepia(66%) saturate(6420%) hue-rotate(200deg) brightness(75%) contrast(92%);
  }
`

export const TeamCard = (props) => {
    let {name, role, photo, photoSize, description, mail, linkedIn} = props;
    photo = photo ? photo : "/icons/user-icon.svg";
    let linkMail = "mailto:" + mail;
    photoSize = photoSize ? photoSize : 120;

    return (
    <Card>
      <BeeContainer>
        <BeeLogo src="/icons/bee-pencil.svg" alt="beePencilLogo"/>
      </BeeContainer>
      
      <UserInfo>
        <UserPic style={{backgroundImage: `url(${photo})`, backgroundSize: `${photoSize}px`}}/>
        <TeamName>{name}</TeamName>
        <MemberRole>{role}</MemberRole>
        <MemberDescription>{description}</MemberDescription>
        <Email href={linkMail}>{mail}</Email>
        <LinkedIn href={linkedIn}>
          <img src="/icons/linkedin-icon.svg" alt="Users LinkedIn"/>
        </LinkedIn>
      </UserInfo>

    </Card>
    );

}

