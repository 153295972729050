import React from "react";
import styled from "@emotion/styled";
import { css, FormLabel } from "@chakra-ui/react";

import { Select } from "chakra-react-select";
// import { algorithms } from "./algorithms";
import { algorithms } from "../../algorithms";

const MultiSelector = styled(Select)`
  background-color: white;
  border-radius: 20px;
  flex-grow: 1;
`;

export const DropdownMenu = (props) => {
  const { setAlgorithms } = props;

  return (
    <>
      <FormLabel>Select Algorithms</FormLabel>
      <MultiSelector
        isMulti
        name="algorithms"
        options={algorithms}
        placeholder="Select Algorithms..."
        closeMenuOnSelect={false}
        onChange={(e) => {
          setAlgorithms(e);
        }}
      />
    </>
  );
};
