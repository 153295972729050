export const members = [
    {
        name: "Youngsun Moon",
        role: "Researcher/Project Manager",
        photo: "/profile_pics/Moon_profile.png",
        photoSize: "120",
        mail: "moonys@uci.edu",
        linkedIn: "https://www.linkedin.com/in/youngsun-moon-7000a6206/",
        description: "I am a Ph.D. student at UC Irvine’s School of Education. My research interests are broadly focused on understanding the reading and writing development of students, especially English Language learners.",
    },
    {
        name: "Andrew Le",
        role: "Software Engineer",
        photo: "/profile_pics/Andrew_pfp.jpg",
        photoSize: "120",
        mail: "andrew.mh.le@gmail.com",
        linkedIn: "https://www.linkedin.com/in/hi-im-andrew/",
        description: "I’m an Informatics alumni from UC Irvine who likes to build cool, fun, and practical apps; I also do Linux system administration on the side. My interests include video games, innovative gadgets, mechanical keyboards, anime, and playing around with generative AI.",
    },
    {
        name: "Aaron Levuano",
        role: "Software Engineer",
        photo: "/profile_pics/Aaron_pfp.png",
        photoSize: "",
        mail: "aaronluevano88@gmail.com",
        linkedIn: "https://www.linkedin.com/in/aaron-luevano-b713351ba/",
        description: "I am an Informatics alumni from UC Irvine student with a specialization in  Human-Computer Interaction. My interests include front-end design and UI/UX research design. Apart from my academic pursuits, I enjoy playing video games, watching soccer, playing guitar, and learning about new technologies.",
    },
    {
        name: "Drew Anzalone",
        role: "Software Engineer",
        photo: "/profile_pics/Drew_3.png",
        photoSize: "",
        mail: "drew.a.anzalone@gmail.com",
        linkedIn: "https://www.linkedin.com/in/drew-anzalone-4b005827a/",
        description: "I am a Software Engineer from UCI who enjoys the more technical side of development. From low level code to high level analysis, algorithms are my thing. I like working with people and have interests in bleeding edge technologies.",
    },
    {
        name: "Jonas Chen",
        role: "Software Engineer",
        photo: "profile_pics/Jonas_profilepic.png",
        photoSize: "130",
        mail: "jonaschen2000@gmail.com",
        linkedIn: "https://www.linkedin.com/in/jonas-chen-3bbb65239/",
        description: "I am a Software Engineering alumni from UC Irvine who enjoys a wide range of software development, from low level coding, high level coding, and full stack. My interests include sim racing, playing video games, aviation and bleeding edge technologies.",
    },
    {
        name: "Orviel Alzate",
        role: "Software Engineer",
        photo: "profile_pics/Orviel_profilepic.png",
        photoSize: "140",
        mail: "orvielalz@gmail.com",
        linkedIn: "https://www.linkedin.com/in/orvielmalzate/",
        description: "I am an Informatics alumni from UC Irvine with a specialization in HCI. My interests include learning front-end development and UI/UX research and design. Outside of academics, I enjoy playing video games, building mechanical keyboards, and being a fanatic of horror films.",
    }
];