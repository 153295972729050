import React from "react";
import { Flex } from "@chakra-ui/react";
import { Navbar } from "components";

const Teacher: React.FC = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Navbar />
      <h1>Teacher</h1>
    </Flex>
  );
};

export default Teacher;
