export const algorithms = [
    {
        label: "Levenshtein",
        value: "levenshtein",
        definition: "Levenshtein distance counts the number of edits (e.g., addition, deletion, substitution) required to transform a string to its correct form. Thus, it is also known as a type of “edit” distance. e.g., target word bird; insertion birld, deletion bid, substitution berd" 
    },
    {
        label: "Normalized Levenshtein",
        value: "normalized_levenshtein",
        definition: "Levenshtein divided by string length. Since the Levenshtein distance is dependent on the length of the string being compared, we can not directly compare the Levenshtein distance between words of different length. The normalized Levenshtein distance can be more intuitive. We can calculate a normalized Levenshtein distance score by dividing it by the length of the word or string. The score range would be between 0 and 1 and comparable between different words." 
    },
    {
        label: "Damerau-levenshtein",
        value: "damerau_levenshtein",
        definition: 'Damerau-Levenshtein distance counts the number of edits (e.g., addition, deletion, substitution, transposition) required to transform a string to its correct form. Thus, it is also known as a type of “edit” distance. e.g., target word bird; insertion birld, deletion bid, substitution berd, and transposition brid'
    },
    {
        label: "Normalized Damerau-levenshtein",
        value: "normalized_damerau_levenshtein",
        definition: 'Since the Damerau-Levenshtein distance is dependent on the length of the string being compared, we can not directly compare the Damerau-Levenshtein distance between words of different length. The normalized Damerau-Levenshtein distance can be more intuitive. We can calculate a normalized Damerau-Levenshtein distance score by dividing it by the length of the word or string. The score range would be between 0 and 1 and comparable between different words.'
    },
    {
        label: "Cosine Distance",
        value: "cosine",
        definition: 'Cosine distance measures the distance between spellings by treating letters as vectors and calculating the cosine of the angles between letters. In other words, cosine distance looks for the letter overlap between the two words or strings regardless of word length. Thus, we can consider this as a token based distance measure.'
    },
    {
        label: "Editex",
        value: "editex",
        definition: 'Editex measures the distance between two words or strings based on the phonological representation of the words. For instance, the editex score for the strings “Clare” and “Claire” would be 0 since there is no difference in the pronunciation of the two strings although the spellings themselves are different.'
    },
    {
        label: "Normalized Editex",
        value: "normalized_editex",
        definition: "Editex divided by maximum possible distance. Similar to the normalized Damerau-Levenshtein score, Editex scores are dependent on the length of the word. The normalized Editex score divides the original score by the maximum possible distance for the word. The score range is between 0 and 1 and is comparable between different words." 
    },
    {
        label: "Prefix similarity",
        value: "prefix",
        definition: 'Prefix similarity is how many letters there are once the prefixes of each word diverge. Some examples include “test” “text” → 2, “tree” “trees”→ 1, “tree” “atree” → 5.'
    },
    {
        label: "Postfix similarity",
        value: "postfix",
        definition: 'Postfix similarity is the same as prefix similarity except reversed - it’s counting how many letters there are until the postfixes of each word converge. Using the same examples from above are “test” “text” → 3, “tree” “trees”→ 5, “tree” “atree” → 1.'
    },
    {
        label: "Correctness",
        value: "correctness",
        definition: 'Shows a 1 if the spelling is correct, 0 otherwise.'
    }
];