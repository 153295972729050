import React from "react";
import { Flex, Button as CButton } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Navbar } from "components";
import { Link } from "react-router-dom";


const Heading = styled.div`
  justify-content: center;
  display: flex;
  font-size: 60pt;
  font-family: 'Schoolbell', cursive;
  flex-grow: 1;
  margin-top: 30px;

`

const BeeLogo = styled.img`
  flex-grow: 0;
  width: 25vw;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;

  p {
    font-size: 18pt;
    margin-bottom: 0.2em;
  }
`

const VisitPages = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  width: 25vw;
  text-align: center;
  justify-content: space-between;

  p {
    font-size: 14pt;
  }
`

const PagesInfo = styled.div`
  display: flex;
  margin: 10px 10vw;
  justify-content: space-between;
`

const Home : React.FC = () => {
  return (
    <Flex direction="column" minH="100vh">
      <Navbar />
      <Header>
        <BeeLogo src="/icons/bee-pencil.svg" alt="beePencilLogo"/>
        <Heading>Welcome to Spelling Dystance</Heading>
      </Header>
      <Information>
        <p>This website is an educational utility for teachers, students, and researchers.</p>
        <p>We provide diverse scoring methods for spelling beyond binary correctness.</p>
      </Information>
      <PagesInfo>
        <VisitPages>
          <p>Learn about us and the scoring methods</p>
          <Link to="/about">
            <CButton colorScheme='teal' size="lg">About Us</CButton>
          </Link>
        </VisitPages>
        <VisitPages>
          <p>Explore the scoring algorithms!</p>
          <Link to="/demo">
            <CButton colorScheme='teal' size="lg">Try the Demo</CButton>
          </Link>
        </VisitPages>
        <VisitPages>
          <p>Analyze your data using the algorithms</p>
          <Link to="/analyze">
            <CButton colorScheme='teal' size="lg">Batch Analysis</CButton>
          </Link>
        </VisitPages>

      </PagesInfo>

    </Flex>
  );
};

export default Home;
